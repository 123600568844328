export const isAdobePopupBannedCountry = () => {
    const bannedCountries = [
        'RU',
        'UA',
        'BY',
        'CA',
        'US',
        'AD',
        'AL',
        'AT',
        'AU',
        'AX',
        'BA',
        'BE',
        'BG',
        'CH',
        'CY',
        'CZ',
        'DE',
        'DK',
        'EE',
        'ES',
        'FI',
        'FK',
        'FO',
        'FR',
        'GB',
        'GG',
        'GI',
        'GL',
        'GR',
        'HR',
        'HU',
        'IE',
        'IL',
        'IM',
        'IS',
        'IT',
        'JE',
        'JP',
        'KR',
        'LI',
        'LT',
        'LU',
        'LV',
        'MC',
        'ME',
        'MT',
        'NL',
        'NO',
        'PL',
        'PT',
        'RO',
        'RS',
        'SE',
        'SI',
        'SK',
        'SM',
        'XK',
    ];

    return bannedCountries.indexOf(USER_COUNTRY_CODE) != -1;
};